import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import { Col, Container, Image, Row } from "react-bootstrap";
import styles from "./iotDevelopment.module.css";
import ServiceMainContent from "../../components/ServiceMainContent";
import { Helmet } from "react-helmet-async";
import ContactFormService from "../../components/ContactFormService";
import { GoDash } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { FaLongArrowAltRight } from "react-icons/fa";

const IotDevelopment = () => {
  const serviceSchema = {
    "@context": "https://schema.org",
    "@type": "Service",
    serviceType: "IoT Development",
    provider: {
      "@type": "Organization",
      name: "Orbio Solutions",
      url: "https://www.orbiosolutions.com",
    },
    description:
      "Explore advanced IoT development services by Orbio Solutions, offering secure, scalable IoT systems to enhance business operations across diverse industries.",
    offers: {
      "@type": "Service",
      url: "https://www.orbiosolutions.com/iot-development",
    },
  };

  const navigate = useNavigate();

  let caseStudy = {
    id: "0",
    heading: "Seamless IoT Integration in existing ERP",
    desc: "Elevating Operations with Expert IoT Consulting and Integration with Existing ERP Systems",
    img: "/assets/iot-lap-screen.WebP",
    url: "/case-study/seamless-IoT-integration-in-existing-erp",
  };
  return (
    <>
      <Helmet>
        <title>
          IoT Development Services | Secure & Scalable IoT Solutions
        </title>
        <meta
          name="keywords"
          content="IoT technology services company and startup"
        />
        <meta
          name="description"
          content="Explore advanced IoT development services by Orbio Solutions, offering secure, scalable IoT systems to enhance business operations across diverse industries."
        />
        <meta
          property="og:title"
          content="IoT Software Development Company | Advanced IoT Solutions"
        />
        <meta
          property="og:description"
          content="Discover Orbio Solutions, a top IoT software development company, providing tailored Internet of Things application development for seamless device connectivity."
        />
        <script type="application/ld+json">
          {JSON.stringify(serviceSchema)}
        </script>
      </Helmet>
      <CommonBannerImage backgroundImageURL="/assets/iot-background.WebP">
        <div className={styles.overlay}></div>
        <div className={styles.textContainer}>
          <h1>
            IoT Development Services: Unlock the Power of Smart Connectivity
          </h1>
          <h6>
            Enhance your business capabilities with Orbio's cutting-edge IoT
            Development Services, designed to create seamless, intelligent
            systems for a more connected world. Get in touch today and begin
            your journey toward innovation and efficiency.
          </h6>
        </div>
      </CommonBannerImage>
      <div>
        <Container>
          <Row>
            <Col md={6} className={styles.anlyseContainer}>
              <h2 className="mb-1 fs-3 text-black">
                IoT Development Services:
              </h2>
              <h5 className="mb-3 overViewTitle fs-5">
                <GoDash style={{ color: "#007fc7" }} />
                Transform Your Business with IoT Development Services
              </h5>
              <p>
                The Internet of Things (IoT) is revolutionizing industries by
                enabling devices to communicate, share data, and perform complex
                tasks autonomously. Orbio Solutions is at the forefront of this
                transformation, providing cutting-edge IoT development services
                designed to enhance the performance of interconnected systems
                across various sectors. Our expertise allows us to bridge the
                gap between the physical and digital worlds, delivering
                solutions that drive innovation, efficiency, and growth.
              </p>
              <p>
                Whether you want to automate lighting systems or develop
                intelligent healthcare robotics, our IoT solutions can help your
                business unlock new possibilities.
              </p>
              <button
                onClick={() => navigate("/contact-us")}
                className={styles.iotBtn}
              >
                Request IoT development services{" "}
                <FaLongArrowAltRight className="ps-1 fs-5" />
              </button>
            </Col>
            <Col
              md={6}
              className={`${styles.anlyseImgContainer} d-flex justify-content-center align-items-center`}
            >
              <Image
                alt="iot"
                src="/assets/iot-screen.WebP"
                fluid
                style={{ objectFit: "contain", height: "450px", width: "100%" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4" style={{ background: "#f5f5f5" }}>
        <Container>
          <Row>
            <Col md={6}>
              <h3 className="ps-3 pt-3">
                Tailored IoT Solutions for Every Industry
              </h3>
              <p className="ps-3 pt-3">
                At Orbio Solutions, we understand that every industry has unique
                needs. We offer IoT development services tailored to specific
                sectors, ensuring maximum impact and efficiency. From retail to
                healthcare and manufacturing, our team creates smart
                applications that improve connectivity, enhance user
                experiences, and boost operational efficiency.
              </p>
              <div className="ps-3 pt-1">
                <h6 className="fw-bold">Industry Applications Include:</h6>
                <ul>
                  <li className="text-black">
                    <span className="fw-bold">Smart Homes and Offices:</span>{" "}
                    Automating lighting, security, and energy management
                    systems.
                  </li>
                  <li className="text-black">
                    <span className="fw-bold">Healthcare:</span> Developing
                    robotic and embedded systems for enhanced patient care.
                  </li>
                  <li className="text-black">
                    <span className="fw-bold">Retail:</span> Streamlining
                    inventory and customer experiences through connected
                    devices.
                  </li>
                  <li className="text-black">
                    <span className="fw-bold">Manufacturing:</span> Improving
                    production lines with real-time monitoring and predictive
                    maintenance.
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                fluid
                src="/assets/automation.jpg"
                alt="Tailored IoT"
                style={{ objectFit: "contain", height: "300px", borderRadius:"8px" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-4">
        <Container>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center order-1 order-md-0"
            >
              <Image
                fluid
                src="/assets/iotSoftware.png"
                alt="IoT Software"
                style={{ objectFit: "cover", height: "350px", width:"100%", borderRadius:"8px" }}
              />
            </Col>
            <Col md={6} className="order-0 order-md-1">
              <h3 className="mb-4">
                IoT Software Development Company – Transforming Ideas into
                Reality
              </h3>
              <p>
                As a leading IoT software development company, Orbio Solutions
                excels at turning ideas into functional, scalable solutions.
                Software is the backbone of any IoT system, enabling devices to
                communicate, perform actions, and generate valuable insights.
                Our team specializes in crafting custom IoT software that
                integrates seamlessly with hardware and business systems.{" "}
              </p>
              <h4 className="mt-1">What We Offer:</h4>
              <ul className={`mt-1`}>
                <li className="text-black">Custom IoT software development</li>
                <li className="text-black">
                  Scalable solutions that grow with your business
                </li>
                <li className="text-black">
                  Secure and reliable data communication across devices
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-5">
        <Container>
          <Row>
            <Col md={6}>
              <h3 className="mb-4">Hardware Development and Prototyping</h3>
              <p>
                Creating reliable and innovative hardware is crucial for any IoT
                solution. Orbio Solutions excels in hardware development and
                prototyping, ensuring that devices are efficient and fully
                integrated with your IoT ecosystem. Our hardware engineers focus
                on building prototypes that bring your ideas to life before
                scaling them for real-world applications.
              </p>
              <h4 className="mt-1">Our Hardware Services Include:</h4>
              <ul className={`mt-1`}>
                <li className="text-black">
                  Designing IoT devices that are optimized for your business
                </li>
                <li className="text-black">
                  Prototyping and testing for performance and reliability
                </li>
                <li className="text-black">
                  Full-scale development and deployment of IoT hardware
                </li>
              </ul>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                fluid
                src="/assets/hardware.WebP"
                alt="Hardware Development"
                style={{ objectFit: "cover", height: "350px", width:" 100%", borderRadius:"8px" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-5">
        <Container>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center order-1 order-md-0"
            >
              <Image
                fluid
                src="/assets/appDevelop.WebP"
                alt="Application Development"
                style={{ objectFit: "cover", height: "350px", widht:"100%", borderRadius:"8px" }}
              />
            </Col>
            <Col md={6} className="order-0 order-md-1">
              <h3 className="mb-4">
                Internet of Things Application Development – Connecting the
                Physical and Digital
              </h3>
              <p>
                At Orbio Solutions, we develop Internet of Things (IoT)
                applications that seamlessly connect the physical and digital
                worlds. Our IoT applications help businesses automate processes,
                enhance user experiences, and make data-driven decisions.
              </p>
              <h4 className="mt-1">Applications Include:</h4>
              <ul className={`mt-3`}>
                <li className="text-black">
                  Smart cities: Managing infrastructure like traffic signals,
                  parking systems, and public lighting.
                </li>
                <li className="text-black">
                  Industrial IoT: Real-time monitoring and analytics to improve
                  operational efficiency.
                </li>
                <li className="text-black">
                  Healthcare IoT: Creating devices that enhance patient care and
                  optimize hospital operations.
                </li>
                <li className="text-black">
                  Retail IoT: Implementing customer behavior tracking and smart
                  inventory management.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-5">
        <Container>
          <Row>
            <Col md={6}>
              <h3 className="mb-4">
                Seamless Integration with Existing ERP Systems
              </h3>
              <p>
                Maximize the value of your IoT ecosystem with seamless
                integration into your existing ERP systems. Orbio Solutions
                specializes in integrating IoT with business management
                platforms, ensuring that data flows effortlessly between your
                IoT devices and core business systems. This allows for enhanced
                visibility, smarter decision-making, and streamlined operations.
              </p>
              <h4 className="mt-1">Benefits of IoT-ERP Integration:</h4>
              <ul className={`mt-1`}>
                <li className="text-black">
                  Real-time data monitoring and analysis
                </li>
                <li className="text-black">
                  Automated workflows and improved productivity
                </li>
                <li className="text-black">
                  Enhanced visibility into business operations and customer
                  behaviors
                </li>
              </ul>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                fluid
                src="/assets/erp.jpg"
                alt="IoT-ERP Integration"
                style={{ objectFit: "cover", height: "350px", width:" 100%", borderRadius:"8px" }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-5 mb-4">
        <Container>
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center order-1 order-md-0"
            >
              <Image
                fluid
                src="/assets/performance.WebP"
                alt="IoT Security"
                style={{ objectFit: "cover", height: "350px", width:" 100%", borderRadius:"8px", filter: "grayscale(100%)" }}
              />
            </Col>
            <Col md={6} className="order-0 order-md-1">
              <h3 className="mb-4">Robust IoT Security Implementation </h3>
              <p>
                With the rise of interconnected devices, security is more
                critical than ever. Orbio Solutions takes security seriously,
                implementing robust measures to ensure the safety and integrity
                of your IoT systems. Our solutions are designed to protect your
                data, devices, and network from potential vulnerabilities.
              </p>
              <h4 className="mt-1">Key Security Features:</h4>
              <ul className={`mt-1`}>
                <li className="text-black">End-to-end encryption</li>
                <li className="text-black">Multi-factor authentication</li>
                <li className="text-black">
                  Regular security audits and updates
                </li>
                <li className="text-black">Data integrity preservation</li>
              </ul>
              <p>
                By securing every layer of your IoT system, we ensure your
                business remains safe from external threats while maintaining
                uninterrupted service.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <ServiceMainContent caseStudy={caseStudy} />
      <ContactFormService caseStudyLength={1} />
    </>
  );
};

export default IotDevelopment;
